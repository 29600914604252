import React, { useEffect, useState } from 'react'
import { BASE } from './../../basedata/constants'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'

interface Props {
  onResponse: (response: any) => void
}

const DocumentPassportMessage = ({ onResponse }: Props) => {

  const resetProccess = async () => {

   const currentToken:any = window.sessionStorage.getItem('token')

    return fetch(`${BASE.user_biometry.base_url}${BASE.user_biometry.invalidate_document}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: BASE.api.token
      },
      body: JSON.stringify({
        token: currentToken,
      })
    })
      .then((resg) => resg.json())
      .then((responseSave) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('doc', 'false');
        window.location.search = searchParams.toString();
        onResponse({ step: 'faceproceed' })
      })
      .catch((error) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('doc', 'false');
        window.location.search = searchParams.toString();
        onResponse({ step: 'faceproceed' })
      })
  }



  useEffect(() => {
    window.gtag('event', 'Onboarding: Document Error', {
      'event_category': 'onboarding',
      'event_label': 'error_document',
      'value': 1
    });
  }, [])



  return (
    <>
      <h1>
      <span>Analisando</span> Documentos
      </h1>
      <hr />
      <p>
      Seus documentos estão sendo validados. Qualquer dúvida,
      <a href="https://api.whatsapp.com/send/?phone=555121068003&text=Ol%C3%A1%2C+eu+gostaria+de+ajuda!&type=phone_number&app_absent=0"
      target='_blank'
      rel="noreferrer"
      className='info-link'
      > click aqui</a> e fale com nosso suporte.
      </p>
    </>
  )
}

export default DocumentPassportMessage
