import React, { Component, useCallback, useEffect, useState } from 'react'
import './OnboardCard.sass'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsCardHeading,
  BsEmojiWink,
  BsAirplane
} from 'react-icons/bs'

import { PiUserSquare } from "react-icons/pi";


import { TbCar } from "react-icons/tb";

import { LuScanFace, LuCar } from "react-icons/lu";


const validateDocument = (cpf:any) => {
  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
  }

  let soma = 0;
  for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
      resto = 0;
  }
  if (resto !== parseInt(cpf.charAt(9))) {
      return false;
  }
  soma = 0;
  for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
      resto = 0;
  }
  if (resto !== parseInt(cpf.charAt(10))) {
      return false;
  }

  return true;
}


interface Props {
  onResponse: (response: any) => void
}

declare global {
  interface Window {
    constraints: any
    stream: any
  }
}

const constraints = (window.constraints = {
  video: true
})

function DocumentInstructions({ onResponse }: Props) {
  const [photoState, setphotoState] = useState<string>('')

  const [userHasCamera, setUserHasCamera] = useState(true)

  const [isPassport, setIsPassport] = useState(false)

  const handleResponse = useCallback((message: string) => {
    setphotoState(message)
  }, [])

  useEffect(() => {
    const docCheck = window.sessionStorage.getItem('doc')
    if(!validateDocument(docCheck)){
      setIsPassport(true)
    }
    const params = new URLSearchParams(window.location.search) // id=123
    if (params.get('fixBrowser')) {
      setphotoState('mobile')
    }

    window.gtag('event', 'Onboarding: Document Instructions', {
      'event_category': 'onboarding',
      'event_label': 'doc_instructions',
      'value': 1
    });
  }, [])

  const handleSuccess = (stream: any) => {
    setUserHasCamera(true)
    const videoTracks = stream.getVideoTracks()
  }

  return (
    <>
      <div className={`${photoState} card`}>
        {userHasCamera ? (
          <div className="have-camera">
            <h1>
              Algumas <span>dicas</span>
            </h1>
            <ul className="instruction-list list-group">
              <li className="list-group-item">
                <i>
                  <BsSun />
                </i>
                Encontre um local iluminado e com plano neutro, como uma mesa ou superfície branca
              </li>
              <li className="list-group-item">
                <i>
                  <BsCardHeading />
                </i>
                Encaixe a imagem do documento na moldura
              </li>
            </ul>

            <p className='mt-3'>Escolha abaixo o tipo de documento que vai enviar:</p>
            
            <div className="form-group">
              {isPassport ? (

<a
className="btn btn-primary btn-document-choose d-flex align-items-center"
onClick={() => onResponse({ step: 'documentproceed', docType:'PASSAPORTE' })}
>
<i>
  <BsAirplane />
</i>
<p>
  <span>Passaporte</span>
</p>
</a>
              ):(
                <>
<a
                className="btn btn-primary btn-document-choose d-flex align-items-center"
                onClick={() => onResponse({ step: 'documentproceed', docType:'RG' })}
              >
                <i>
                  <LuScanFace />
                </i>
                <p>
                  <span>RG</span>
                  Documento de identidade
                </p>
              </a>

              <a
                className="btn btn-primary btn-document-choose d-flex align-items-center"
                onClick={() => onResponse({ step: 'documentproceed', docType:'CNH' })}
              >
                <i>
                  <LuCar />
                </i>
                <p>
                  <span>CNH</span>
                  Carteira nacional de habilitação
                </p>
              </a>
              </>
              )}
              
            </div>
          </div>
        ) : (
          <div className="no-camera">
            {' '}
            <h1>
              Não achamos <span>sua câmera</span>
            </h1>
            <p>
              Não conseguimos acessar sua câmera para prosseguir. <br />
              Por favor, revise as permissões do seu aplicativo ou tente com
              outro celular
            </p>
            <div className="form-group form-submit">
              <a
                className="btn btn-primary form-control form-control-lg"
                onClick={() => window.location.reload()}
              >
                Recarregar
              </a>
            </div>
          </div>
        )}
      </div>
      <ul className="step-counter">
        <li className="active"></li>
        <li className="active"></li>
        <li className="active"></li>
        <li></li>
      </ul>
    </>
  )
}

export default DocumentInstructions
